export const testimonial_data = [
	{
		title: 'Entrepreneur / Manager',
		desc: "Their expertise in blockchain technology and user-centric design helped us create a seamless and engaging decentralized application. ",
		id: '0',
		name: 'Alex Fox',
		img: '/images/avatars/avatar1.png',
	},
	{
		title: 'General Partner at Entrepreneur',
		desc: "Their blockchain ecosystem knowledge and ability to simplify complicated ideas are impressive.",
		id: '1',
		name: 'Katie Smith',
		img: '/images/avatars/avatar2.png',
	},
	{
		title: 'CTO of Blockchain Innovations',
		desc: "Alchemis' attention to detail, user-centered approach, and technological skills enabled a successful launch. We value their expertise and dedication.",
		id: '2',
		name: 'Kelvin Lim',
		img: '/images/avatars/avatar3.png',
	},
  {
    title: 'CEO of CryptoVentures',
    desc: "Working with Alchemis is a pleasure since they are patient in hearing your needs and eager to solve your challenge creatively. ",
    id: '4',
    name: 'Wei Liang',
    img: '/images/avatars/avatar4.png',
  },
  {
    title: 'Founder of DecentralizeX',
    desc: "We love their speedy problem-solving and high-quality items!",
    id: '5',
    name: 'Hiroshi',
    img: '/images/avatars/avatar5.png',
  },
];
