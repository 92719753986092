const SingleFeature = ({ featureList }) => {
  let bg = 'dark:bg-jacarta-700'
  if (!featureList) {
    bg = 'dark:bg-[#070B2F]'
    featureList = [
      {
        id: 1,
        icon: '/images/features/efficiency.svg',
        title: 'Expertise',
        text: `We are a team of seasoned blockchain developers, consistently staying ahead of industry advancements.`
      },
      {
        id: 2,
        icon: '/images/features/convenience.svg',
        title: 'Custom Solutions',
        text: `We don't believe in one-size-fits-all. We create solutions tailored to your unique business needs.`
      },
      {
        id: 2,
        icon: '/images/features/security.svg',
        title: 'Trust',
        text: `With Alchemis, you're partnering with a reliable and transparent company committed to your success.`
      },
    ]
  }

  return (
    <>
      {featureList?.map((item) => (
        <div
          className={`mb-12 rounded-2.5xl border border-jacarta-100 bg-white p-8 pt-0 text-center transition-shadow hover:shadow-xl dark:border-jacarta-600 ${bg}`}
          key={item.id}
        >
          <div className="mb-9 -mt-8 inline-flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-full border border-jacarta-100 bg-white dark:border-jacarta-600 dark:bg-[#131740]">
            <img
              src={item.icon}
              alt="icon"
              width="24"
              height="24"
              className="h-12 w-12"
            />
          </div>

          <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
            {item.title}
          </h3>
          <p className="dark:text-jacarta-300">{item.text}</p>
        </div>
      ))}
    </>
  );
};

export default SingleFeature;
