import React from 'react';

export const SectionHeader = ({title, subTitle, containerClass, titleProps, subTitleProps, subTitleContainer}) => {
  return (
    <div className={containerClass ?? 'mb-12'}>
      <h1 className="font-display text-center text-3xl font-medium text-white" {...titleProps} dangerouslySetInnerHTML={{ __html: title }}/>
      {subTitle && (
        <div className="mx-auto" {...subTitleContainer}>
          <p className="mt-6 text-lg text-center leading-normal dark:text-jacarta-300" {...subTitleProps} dangerouslySetInnerHTML={{ __html: subTitle }}/>
        </div>
      )}
    </div>
  )
}
