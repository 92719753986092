import SingleFeature from "./SingleFeature";
import { SectionHeader } from '@apps/components/section-header';
import React from 'react';

const FeaturesCard = ({title, subTitle, featureList, buttonText, buttonUrl}) => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <SectionHeader title={title} subTitle={subTitle} containerClass={"mx-auto mb-20 text-center"}/>
        <div className="grid gap-7 md:grid-cols-3">
          <SingleFeature featureList={featureList}/>
        </div>
        {buttonText && (
          <div className="flex justify-center">
            <a
              href={buttonUrl}
              className="rounded-full bg-primary py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark"
            >
              {buttonText}
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturesCard;
