const Hero_10 = ({content}) => {
  return (
    <section className="relative py-20 lg:pt-48">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img src={content?.backgroundImage} alt="gradient dark" style={{width: '100%'}}/>
      </picture>

      <div className="container">
        <div className="h-full items-center gap-4 flex flex-col lg:grid lg:grid-cols-12">
          <div className="flex h-full flex-col items-center justify-center py-10 lg:col-span-6 lg:items-start lg:py-20 order-last lg:order-first">
            <h1 className="mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white lg:text-left lg:text-6xl">
              {content?.title}
            </h1>
            <p className="mb-8 max-w-md text-center text-md dark:text-jacarta-300 lg:text-left">
              {content?.descriptions}
            </p>
            {content?.btnUrl && content?.btnText && (
              <a
                href={content.btnUrl}
                className="rounded-full bg-primary py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark"
              >
                {content.btnText}
              </a>
            )}
          </div>
          {/* End flex-col */}

          {/* <!-- Hero image --> */}
          <div className="col-span-6">
            <div className="relative text-center order-first lg:order-last">
              <img
                src={content?.headImage}
                alt="hero"
                style={{height: 'auto', width: 1120}}
                className="inline-block"
              />
              {content?.animationImage && (
                <img
                  src={content?.animationImage}
                  alt=""
                  style={{height: 'auto', width: 1120}}
                  className="animate-fly absolute top-0 -z-10"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero_10;
