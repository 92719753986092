import Link from 'next/link';

const CallToActions = ({
  title,
  background,
  description,
  btnText,
  link,
}) => {
  return (
    <div className='container' style={{ marginBottom: '-148px' }}>
      <div
        className='z-10 overflow-hidden rounded-2.5xl px-16 py-24 lg:px-28 relative bg-cover bg-center after:absolute'
        style={{ backgroundImage: `url(${background})` }}>
        <div className='lg:flex lg:justify-between'>
          <div className='mb-6 max-w-lg lg:mb-0'>
            <h2 className='mb-5 font-display text-3xl text-jacarta-700 dark:text-white'>
              {title}
            </h2>
            <p className='text-lg leading-normal dark:text-jacarta-300'>
              {description}
            </p>
          </div>
          <Link href={link} legacyBehavior>
            <a
              className='inline-block self-center rounded-full bg-primary py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
            >
              {btnText}
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CallToActions;
